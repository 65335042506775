import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comments"
export default class extends Controller {
  connect() {
    if ($('.added-comment').length > 0) {
      $('.no-comments').addClass('d-none');
    } else {
      console.log('No comment elements found.');
    }

    $(document).on('click', '.swal-confirm', function(event) {
      var $link = $(this);
      event.preventDefault();

      var iconType = $link.data('icon') || 'warning';
      var confirmMessage = $link.data('confirm-message') || 'Are you sure?';
      var confirmText = $link.data('confirm-text') || 'OK';
      var cancelText = $link.data('cancel-text') || 'Cancel';
      var htmlContent = $link.data('icon') == 'warning2' ? '<img src="https://img.icons8.com/?size=256&id=1lpBESDEGeuX&format=png" class="swal2-custom-icon"/>' : '';
      htmlContent = htmlContent + `<div class="swal2-html-container">${confirmMessage}</div>`;

      // SweetAlert confirmation dialog
      Swal.fire({
        icon: iconType,
        html: htmlContent,
        showCancelButton: true,
        confirmButtonColor: '#d11a2a',
        confirmButtonText: confirmText,
        cancelButtonText: cancelText
      }).then(function(result) {
        if (result.isConfirmed) {
          $.ajax({
            url: $link.attr('href'),
            type: 'DELETE',
            headers: {
              'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
              $link.closest('.comment-row').remove();
            },
            error: function(xhr, status, error) {
              console.error('Delete failed:', error);
            }
          });
        }
      });
    });
  }



  showEditForm(e) {
    var commentId = $(e.target).parent().data('id');
    var editForm = $(`#edit_form_${commentId}`)
    editForm.toggleClass('d-none');
  }

  removeText() {
    setTimeout(function() {
      $("#commentInput").val('');
    }, 1);
  }
}
