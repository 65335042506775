import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="application-v2--leave-setting"
export default class extends Controller {
  connect() {
    $('#year').on('change', function(){
      let employee_id =  $('#employee_id').text();
      let year = $('#year').val();
      
      if(employee_id.length) {
        $.ajax({
          type: 'get',
          url: '/organizers/comp_offs/'+ employee_id +'/update_compoff_balance_sheet.'+year
        });  
      } else {
        $.ajax({
          type: 'get',
          url: '/employee/comp_offs/update_compoff_balance_sheet.'+year
        });
      }
    });
    customSelect2('#leave_balance_policy_leave_type_id', true, 'Please Select', false);
    customSelect2('#leave_balance_policy_designation_ids', true, 'Please Select', false);
    $('#year').datepicker({
      format: 'yyyy',
      minViewMode: 'years',
      autoclose: true,
      maxDate: 0
    });
    var $designationFields = $('#designation-fields');
    var $experienceFields = $('#experience-fields');
    var $designationRadio = $('#entitlement_basis_designation');
    var $experienceRadio = $('#entitlement_basis_experience');

    function toggleEntitlementFields() {
      $designationFields.hide();
      $experienceFields.hide();
      if ($designationRadio.is(':checked')) {
        $designationFields.show();
      } else if ($experienceRadio.is(':checked')) {
        $experienceFields.show();
      }
    }

    $designationRadio.on('change', toggleEntitlementFields);
    $experienceRadio.on('change', toggleEntitlementFields);

    toggleEntitlementFields();

    $("#leave_type_form").validate({
      rules: {
        "leave_type[name]": {
          required: true
        },
        "leave_type[description]": {
          required: true
        }
      },
      messages: {
        "leave_type[name]": {
          required: "Please enter the name."
        },
        "leave_type[description]": {
          required: "Please enter a description."
        }
      },
      submitHandler: function(form) {
        form.submit(); // Submit the form if validation is successful
      }
    });

    // Initialize validation for the main form
    $("#leave_balance_policy_form").validate({
      rules: {
        "leave_balance_policy[designation_ids][]": {
          required: true
        },
        "leave_balance_policy[leave_type_id]": {
          required: true
        },
        "leave_balance_policy[number_of_days]": {
          required: true,
          number: true
        },
        "leave_balance_policy[experience]": {
          required: true,
          number: true
        },
      },
      messages: {
        "leave_balance_policy[designation_ids][]": {
          required: "Please select a designation."
        },
        "leave_balance_policy[leave_type_id]": {
          required: "Please select a leave type."
        },
        "leave_balance_policy[number_of_days]": {
          required: "Please enter the number of days.",
          number: "Please enter a valid number."
        },
        "leave_balance_policy[experience]": {
          required: "Please enter the experience.",
          number: "Please enter a valid number."
        },
      },
      submitHandler: function(form) {
        form.submit(); // Submit the form if validation is successful
      }
    });

    $(document).on('cocoon:after-insert', function(e, addedItem) {
      $(addedItem).find('input, select').each(function() {
        if ($(this).attr('name').includes('value')) {
          // Apply validation to the value field
          $(this).rules("add", {
            required: true,
            messages: {
              required: "Please enter a value."
            }
          });
        } else if ($(this).attr('name').includes('rule_type')) {
          customSelect2($(this), true, 'Please Select', true);
          $(this).rules("add", {
            required: true,
            messages: {
              required: "Please select a rule type."
            }
          });
        }
      });
    });


  }
}



