import { Controller } from "@hotwired/stimulus"
import Swal from "sweetalert2";

// Connects to data-controller="feedback-form-sends"
export default class extends Controller {
  connect() {
    $(".form_feedback").validate()
    customSelect2('.select2', false);

    if($('#appraisal-feedback-form .nested-fields-prev-jobs').length == 0){
      $('#appraisal-feedback-form .add_fields').click()
    }
    setTimeout(function() {
      loadDatePickerOnPrevJob();
    }, 100)
    $('#appraisal-feedback-form .add_fields').click(function() {
      setTimeout(function() {
        loadDatePickerOnPrevJob();
      }, 100)
    })

    $('#appraisal-feedback-form').find(".nested-fields").each(function(index, element) {
      console.log('test-feed')
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
          $(input).datepicker({
            format: 'dd/mm/yyyy',
            startDate: '+0d',
            autoclose: true
          })
          if($(input).attr('class').includes('form_expiry_date')){
            $(input).rules( "add", {
              required: true,
              messages: {
                required: "Please enter deadline date.",
              }
            });
          }
        }
      })
    })

    customSelect2('#feedback_form_send_template_form_id', true);
    customSelect2('#feedback_form_send_employee_id', true);

    $('.form_expiry_date').datepicker({
      format: 'dd/mm/yyyy',
      startDate: '+0d',
      autoclose: true
    })
  }
  confirm(event) {
    event.preventDefault(); // Prevent default form submission

    const form = event.target.closest('form.send-form'); // Locate the closest form element
    console.log(form)
    const confirmMessage = $(form).data('confirmMessage') || 'Are you sure, you want to send this form?';
    const confirmText = $(form).data('confirmText') || 'Ok';
    const cancelText = $(form).data('cancelText') || 'Cancel';
    const icon = $(form).data('icon') || 'warning';

    // Trigger SweetAlert confirmation
    Swal.fire({
      title: confirmMessage,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log($(form))
        $(form).submit();
      }
    });
  }
}


window.loadDatePickerOnPrevJob = function() {
  $('.date-picker').each(function(index, item) {
    $(item).datepicker({
      format: 'dd-mm-yyyy',
      autoclose: true,
      startDate: '01/01/1900',
      endDate: new Date().toLocaleDateString('en-GB')
    })
  })
}
