import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="invoice-transaction"
export default class extends Controller {
  connect() {
    $(".InvoiceTransactionsForm").validate({
      rules: {
        "invoice_transaction[amount]": {
          required: true,
          min: 1,
          maxlength: 12
        },
        "invoice_transaction[paid_at]": {
          required: true
        },
        "invoice_transaction[pay_method]": {
          required: true
        },
        "invoice_transaction[pay_type]": {
          required: true
        }
      },
      messages: {
        "invoice_transaction[amount]": {
          required: "Please enter amount.",
          min: 'Please enter valid amount.',
          maxlength: 'Amount should be between 1-12 digits.'
        },
        "invoice_transaction[paid_at]": {
          required: "Please enter paid date."
        },
        "invoice_transaction[pay_method]": {
          required: 'Please enter pay method.'
        },
        "invoice_transaction[pay_type]": {
          required: 'Please enter pay type.'
        }
      }
    });
    $.validator.addMethod('number_regex', function(value, element){
      return this.optional(element) || /^[0-9]+$/.test(value);
    },'Only digit are allowed');

    $("#invoice_transaction_amount").on('input', function() {
      var val = $(this).val();
      // Remove any characters after the second decimal point
      if(val.indexOf('.') !== -1) {
        var splitVal = val.split('.');
        if(splitVal[1].length > 2) {
          splitVal[1] = splitVal[1].substring(0, 2);
          val = splitVal.join('.');
          $(this).val(val);
        }
      }
    });

    $('#invoice_transaction_paid_at').datepicker({
        format: 'dd/mm/yyyy',
        endDate: '+0d',
        startDate: '-33d',
        autoclose: true
    })
  }
}
