import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notifications"
export default class extends Controller {
  connect() {
    if($('#notification-section-bell').data('broadcast')){
      var title = 'HRM Notification'
      var options = { body: $('#notification-section-bell').data('message') }
      var permission = "granted"
      if (permission === "granted") {
        new Notification(title, options)
      }
    }
    console.log($('#notification-section-bell').data('reload'))
    if($('#notification-section-bell').data('reload')){
      $.ajax({
        url: "/notifications",
        type: "GET",
        success: function(response) {
        }
      });
    }
  }

  dropdownToggle(e){
    $('#notifications-list .custom-dropdown-menu').toggle()
  }
}
