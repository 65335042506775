import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="task"
export default class extends Controller {
  connect() {

    $(".filter-btn").click(function(){
      $(".employee-task").toggleClass("order-enable");
    });

    $(".filter-btn").click(function(){
      $(".employee-team-task").toggleClass("order-enable");
    });


    $('#task_from_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#task_to_date').datepicker('setStartDate', null);
        } else if (selected.date != undefined) {
          var minDate = new Date($("#task_from_date").datepicker("getDate"));
          $('#task_to_date').datepicker('setStartDate', minDate);
        }
      });

    $('#task_to_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#task_from_date').datepicker('setEndDate', new Date());
        } else if (selected.date != undefined) {
          var maxDate = new Date($("#task_to_date").datepicker("getDate"));
          $('#task_from_date').datepicker('setEndDate', maxDate);
        }
      });

    $(".filter-btn").click(function(){
      $(".organizer-task").toggleClass("order-enable");
    });

    var initialFromDate = $('#task_from_date').datepicker('getDate');
    if (initialFromDate != null){
      $('#task_to_date').datepicker('setStartDate', initialFromDate);
    }

    var initialToDate = $('#task_to_date').datepicker('getDate');
    if (initialToDate != null){
      $('#task_from_date').datepicker('setEndDate', initialToDate);
    }

    validatorBasic()
    customSelect2('#task_project_id', true);
    customSelect2('#task_assignee_id', true);
    customSelect2('#task_status', true);
    customSelect2('#task_priority', true);

    $("#task_new_form_id").validate({
      rules: {
        "task[project_id]": {
          required: true,
        },
        "task[name]": {
          required: true
        },
        "task[assignee_id]": {
          required: true
        },
        "task[priority]": {
          required: true
        },
        "task[content]": {
          required: true,
          minlength: 10
        }
      },
      messages: {
        "task[project_id]": {
          required: "Please select project name",
        },
        "task[name]": {
          required: "Please enter task name."
        },
        "task[assignee_id]": {
          required: "Please select assignee name"
        },
        "task[priority]": {
          required: 'Please select the task priority'
        },
        "task[content]": {
          required: 'Please enter description',
          minlength: 'Please enter atleast 10 character.'
        }
      }
    });

    $("#task_edit_form_id").validate({
      rules: {
        "task[status]": {
          required: true,
        },
        "task[project_id]": {
          required: true,
        },
        "task[name]": {
          required: true
        },
        "task[assignee_id]": {
          required: true
        },
        "task[priority]": {
          required: true
        },
        "task[content]": {
          required: true,
          minlength: 10
        },
        "task[estimation_time]": {
          min: 0,
          maxlength: 5
        }
      },
      messages: {
        "task[status]": {
          required: "Please select status.",
        },
        "task[project_id]": {
          required: "Please select project name",
        },
        "task[name]": {
          required: "Please enter task name."
        },
        "task[assignee_id]": {
          required: "Please select assignee name"
        },
        "task[priority]": {
          required: 'Please select the task priority'
        },
        "task[content]": {
          required: 'Please enter description',
          minlength: 'Please enter atleast 10 character.'
        },
        "task[estimation_time]": {
          min: 'Estimation should be between 1-5 digits.',
          maxlength: 'Estimation should be between 1-5 digits.'
        }
      }
    });
  }
}

window.validatorBasic = function(){
  $.validator.addMethod("alphanumeric", function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9 ]+$/.test(value);
  });
}
