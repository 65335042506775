import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="invoice"
export default class extends Controller {
  connect() {

    $('#add-project-btn').click(function(e) {
      e.preventDefault();
      $(this).hide(); // Hide the button
      $('#select-container').removeClass('hidden').find('select').focus(); // Show the select box and focus on it
    });

    // $('.template-card-body').click(function(e){
    //   e.preventDefault();
    //   const radioButton = this.element.querySelector('input[type="radio"]');
    //   if (radioButton) {
    //     radioButton.checked = true; // Check the radio button
    //   }
    // })

    customSelect2('#project-invoice', false);
    $(".invoice_validation").validate({
      rules: {
        "invoice[invoice_number]": {
          required: true
        },
        "invoice[project_id]": {
          required: true
        },
        "invoice[advance_amount]": {
          number_regex: true,
          min: 0,
          maxlength: 12
        },
        "invoice[adjustment_amount]": {
          number_regex: true,
          min: 0,
          maxlength: 12
        },
        "invoice[total_invoice_amount]": {
          required: true,
          number_regex: true
        },
        "invoice[line_items_amount]": {
          required: true,
          number_regex: true
        }
      },
      messages: {
        "invoice[invoice_number]": {
          required: "Please enter invoice number."
        },
        "invoice[project_id]": {
          required: "Please select project."
        },
        "invoice[advance_amount]": {
          min: 'Please enter advance amount',
          maxlength: 'Advance amount should be between 1-12 digits'
        },
        "invoice[adjustment_amount]": {
          min: 'Please enter adjustment amount',
          maxlength: 'Adjustment amount should be between 1-12 digits'
        },
        "invoice[total_invoice_amount]": {
          required: 'Please enter total amount.'
        },
        "invoice[line_items_amount]": {
          required: 'Please enter line Item amount.'
        }
      }
    });

    $("#send_invoice_form").validate({
      rules: {
        "email": {
          required: true,
          emailregex: true
        }
      },
      messages: {
        "email": {
          required: "Please enter invoice number.",
          emailregex: 'Please enter a valid email address'
        }
      }
    });

    $("#invoice_advance_amount, #invoice_adjustment_amount").on('input', function() {
      var val = $(this).val();
      // Remove any characters after the second decimal point
      if(val.indexOf('.') !== -1) {
        var splitVal = val.split('.');
        if(splitVal[1].length > 2) {
          splitVal[1] = splitVal[1].substring(0, 2);
          val = splitVal.join('.');
          $(this).val(val);
        }
      }
    });

    

    $.validator.addMethod('number_regex', function(value, element){
      return this.optional(element) || /^[0-9.]+$/.test(value);
    },'Only digit are allowed');

    $.validator.addMethod('emailregex', function(value, element){
      return this.optional(element) || /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value);
    });

    if($('#project-invoice').val().length > 0){
      $('.invoice_line_items_section').removeClass('active')
    }else{
      $('.invoice_line_items_section').addClass('active')
    }

    if($('.nested-fields').length > 1){
      $('.no_line_item').hide()
      loadSelectForInvoiceTask()
    }else{
      $('.no_line_item').show()
    }

    $(".fromDate").datepicker({
      format: 'dd-mm-yyyy',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('.toDate').datepicker('setStartDate', null);        
        } else if (selected.date != undefined) {
          var minDate = new Date(selected.date.valueOf());
          $('.toDate').datepicker('setStartDate', minDate);
        }
    });

    $(".toDate").datepicker({
         format: 'dd-mm-yyyy',
         autoclose: true,
    }).on('changeDate input', function (selected) {
      if (!$(this).val()) {
        $('.fromDate').datepicker('setEndDate', null);
      } else if (selected.date != undefined) {
        var minDate = new Date(selected.date.valueOf());
        $('.fromDate').datepicker('setEndDate', minDate);
      }
    });

    setTimeout(function() {
      $('#project-invoice').change(function() {
        console.log('Clicked')
        $.each(document.getElementsByClassName('remove_fields'), function(index, item) {
          console.log('')
          $(item).trigger('click')
        });

        if(this.value.length > 0){
          $('.invoice_line_items_section').removeClass('active')
        }else{
          $('.invoice_line_items_section').addClass('active')
        }

        var projectId = this.value;
        $('.invoice-icone-enable').removeAttr("disabled")
        $.ajax({
          url: '/organizers/invoice_line_items/load_tasks',
          method: 'GET',
          data: { project_id: projectId },
          success: function(response) {
            console.log(response)
            $('#task_list_option').val(JSON.stringify(response.tasks));
            $('#billing_logs').val(JSON.stringify(response.billings));
            $('.currency-symbol').text(`(${response.billings.currency})`)
          }
        });
      })
    }, 100)

    
    $('.line-item-per-unit-amount, #invoice_adjustment_amount').on('keydown', function(e) {
      if (e.key === '-' || e.key === '+') {
        // Prevent the default behavior
        e.preventDefault();
      }
    });
  }

  addLineItem(){
    $(".add-invoice-line-item a").click()
    if($('.nested-fields').length > 1){
      $('.no_line_item').hide()
    }else{
      $('.no_line_item').show()
    }
    loadSelectForInvoiceTask()
  }
}

window.loadSelectForInvoiceTask = function() {
  $('select.line-item-task-select').each(function(index, item) {
    if($(item).data('selecttwo') == true){
      $(item).select2()
    }
  })
}