import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="announcement"
export default class extends Controller {
  connect() {
    console.log('test announcements')
    customSelect2('#employee_ids', true, 'All')
    customSelect2("#template_type", true)
    customSelect2("#announcement_type", true)

    $("#announcement_from_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    }).on('changeDate input', function (selected) {
      console.log(selected.date != undefined);
      if (!$(this).val()) {
        $('#announcement_to_date').datepicker('setStartDate', null); // Reset the end date of from_date
      } else  if ( selected.date != undefined ) {
        var minDate = new Date(selected.date.valueOf());
        $('#announcement_to_date').datepicker('setStartDate', minDate);
      }
    })

    $(".filter-btn").click(function(){
      console.log('announcement')
      $(".announcement").toggleClass("order-enable");
    });

    
    $('.datepicker').datepicker({

    })
   
    $("#announcement_to_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    }).on('changeDate input', function (selected) {
      if (!$(this).val()) {
        $('#announcement_from_date').datepicker('setEndDate', null);
      } else  if ( selected.date != undefined ) {
        var maxDate = new Date(selected.date.valueOf());
        $('#announcement_from_date').datepicker('setEndDate', maxDate);
      }
    });

    var initialFromDate = $('#announcement_from_date').datepicker('getDate');
    $('#announcement_to_date').datepicker('setStartDate', initialFromDate);

    var initialToDate = $('#announcement_to_date').datepicker('getDate');
    $('#announcement_from_date').datepicker('setEndDate', initialToDate);

    $("#announcement_event_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      // startDate: '+1d'
    });

    $("#announcement_schedule_date_id").datepicker({
      format: 'dd/mm/yyyy',
      // startDate: '+1d',
    });

    $('#announcement_schedule_automatic').on('click', function(){
      $('#announcement_schedule_date_id').addClass('d-block')
      $('#announcement_schedule_date_id').removeClass('d-none')
    });

    $('#announcement_schedule_manually').on('click', function(){
      $('#announcement_schedule_date_id').addClass('d-none')
      $('#announcement_schedule_date_id').removeClass('d-show')
    });

    $('#template_type').on('change', function(){
      var templateId = $(this).val()
      $.ajax({
        type: "get",
        url: "/organizers/announcements/"+templateId+"/template_access_description"
      });
    });

    $('#template_category_id').on('change', function(){
      var categoryId = $(this).val()
      $.ajax({
        type: "get",
        url: "/organizers/announcements/"+categoryId+"/category_template_access"
      });
    });

    $('.announcementFrom').validate({
      rules: {
        'announcement[title]':{
          required:true,
          minlength: 3,
          maxlength: 40,
          titleRegex: true
        },
        'announcement[type_of_announcement] ':{
          required:true,
        }
      },
      messages: {
        'announcement[title]':{
          maxlength: 'Sorry, title must be between 3 and 40 characters long.',
          minlength: 'Sorry, title must be between 3 and 40 characters long.',
        },
        'announcement[type_of_announcement] ':{
          required: 'Please select type of announcement.',
        }
      }
    })

    $('.SendMailFrom').validate({
      rules: {
        'announcement[template_category_id]':{
          required:true,
        },
        'announcement[template]':{
          required:true,
        },
        'announcement[description]':{
          required:true,
        },
        'announcement[other_members][]': {
          emailregex: true,
        },
      }
    })

    $.validator.addMethod("emailregex", function(value, element) {
      let y = true
      for(let x of value) {
        y = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(x);
        if (y === false) {
          y = false
        }
      }
      return this.optional(element) || y
    }, "Please Enter Valid email");

    $.validator.addMethod("compareAnnouncement", function (value, element) {
      return this.optional(element) || $('#announcement_announcement_expiry_date').val() >= $('#announcement_announcement_date').val()
    }, "expiry date should not less than announcement date");

    $.validator.addMethod("titleRegex", function (value, element) {
      return this.optional(element) || /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/i.test(value)
    }, "Please Enter Valid title...");
  }
}
