import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="loan"
export default class extends Controller {
  connect() {
    customSelect2('#loan_type_of_loan', true);
    console.log('Testing')
    $(document).on("click", "#emp_leave_filter_reset", function () {
      $('input[ name=from_date]').val('');
      $('input[ name=to_date]').val('');
      $('select[ name=status]').val('').change();
    });


    $(".filter-btn").click(function(){
      $(".employee-loan").toggleClass("order-enable");
    });

    $('#from_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#to_date').datepicker('setStartDate', new Date());        
        } else if (selected.date != undefined) {
          var minDate = new Date($("#from_date").datepicker("getDate"));
          $('#to_date').datepicker('setStartDate', minDate);
        }
      });

    $('#to_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#from_date').datepicker('setEndDate', new Date());        
        } else if (selected.date != undefined) {
          var maxDate = new Date($("#to_date").datepicker("getDate"));
          $('#from_date').datepicker('setEndDate', maxDate);
        }
      });
    
    var initialFromDate = $('#from_date').datepicker('getDate');
    if (initialFromDate != null){
      $('#to_date').datepicker('setStartDate', initialFromDate);
    }

    var initialToDate = $('#to_date').datepicker('getDate');
    if (initialToDate != null){
      $('#from_date').datepicker('setEndDate', initialToDate);
    }

    $('#loan_installment_start_month').datepicker({
      format: 'mm/yyyy',
      minViewMode: 'months',
      autoclose: true,
      startView: 'months',
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(new Date().getFullYear() + 8, new Date().getMonth(), 0)
    });

    $(document).ready(function() {
      $('.other_loan').hide();
      $('#loan_type_of_loan').change(function() {
       var type_of_loan =  $('#loan_type_of_loan').val()
        if (type_of_loan == 'other_loan') {
          $('.other_loan').show();
        } else {
          $('.other_loan').hide();
        }
      });
    });


    validatorBasic();
    let tenure = $("#loan_tenure").data('max');
    let loanAmountMax = $("#loan_amount").data('max');
    console.log(tenure)
    let loanAmountMin = $("#loan_amount").data('min');
    $('.LoanFrom').validate({
      rules: {
        "loan[tenure]": {
          required: true,
          number: true,
          max: parseInt(tenure),
          min: 1
        },
        "loan[amount]": {
          required: true,
          max: parseFloat(loanAmountMax),
          min: parseFloat(loanAmountMin)
        },
        "loan[type_of_loan]": {
          required: true,
        },
        "loan[other_loan]": {
          required: true,
          nameregex: true,
          minlength: 3,
          maxlength: 50
        },
        "loan[installment_start_month]": {
          required: true,
        },
        "loan[approve_comment_description]": {
          required: true,
          nameregex: true,
          minlength: 10
        },
        "loan[reason]":{
          required: true,
          minlength: 10
        }
      },
      messages: {
        "loan[tenure]": {
          required: 'This is a required field.',
          max: `Maximum tenure of installment allowed is ${tenure}`,
          min: 'Tenure of installement is less than one month'
        },
        "loan[amount]": {
          required: 'This is a required field.'
        },
        "loan[type_of_loan]": {
          required: 'Please select loan type.'
        },
        "loan[other_loan]": {
          required: 'Please enter other loan.',
          nameregex: 'Only albhabets are allowed.',
          minlength: 'Description must have minimum 3 character long.',
          maxlength: 'Description must have minimum 50 character long.'
        },
        "loan[installment_start_month]": {
          required: 'Please select installment start month.'
        },
        "loan[approve_comment_description]": {
          required: 'Please enter comment.',
          nameregex: 'Only albhabets are allowed.',
          minlength: 'Description must have minimum 10 character long.'
        },
        "loan[reason]":{
          required: "Please enter reason",
          minlength: 'Reason must have minimum 10 character long.'
        }
      }
    });

    $('.rejectLoanForm').validate({
      rules: {
        "loan[reject_comment_description]":{
          required: true,
          minlength: 10
        }
      },
      messages: {
        "loan[reject_comment_description]":{
          required: 'This is a required field.',
          minlength: 'Reason must have minimum 10 character long.'
        }
      }
    });

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z. ]+$/.test(value);
    });

    validatorBasic();
    $('.LoanSettingFrom').validate({
      rules: {
        "loan_setting[percentage_allowed]": {
          required: true,
          number: true,
          min: 1,
          maxlength: 15,
          percentregex: true
        },
        "loan_setting[minimum_amount]": {
          required: true,
          number: true,
          min: 1,
          maxlength: 15,
          amountregex: true
        },
        "loan_setting[tenure]":{
          required: true,
          number: true,
          min: 1,
          maxlength: 2,
        },
        "loan_setting[balance_amount]": {
          required: true,
          number: true
        },
        "loan_setting[target_amount]": {
          required: true,
          number: true,
          min: 1,
          maxlength: 15,
          amountregex: true
        },
        'loan_setting[loan_eligibility_month]': {
          required: true,
          number: true,
          min: 1,
          maxlength: 2,
          percentregex: true
        }
      },
      messages: {
        "loan_setting[percentage_allowed]": {
          required: 'This is a required field.',
          number: "Please enter a valid amount value."
        },
        "loan_setting[minimum_amount]": {
          required: 'This is a required field.',
          number: "Please enter a valid amount value."
        },
        "loan_setting[tenure]":{
          required: 'This is a required field.',
          number: "Please enter a valid amount value."
        },
        "loan_setting[balance_amount]": {
          required: 'This is a required field.',
          number: "Please enter a valid amount value."
        },
        "loan_setting[target_amount]": {
          required: 'This is a required field.',
          number: "Please enter a valid amount value."
        }
      }});

    $.validator.addMethod('percentregex', function(value, element){
      return this.optional(element) || /^(\d{1,3}(\.\d{0,2})?|\.\d{1,2})$/.test(value);
    },'Please enter valid input.');

    $.validator.addMethod('amountregex', function(value, element){
      return this.optional(element) || /^(\d{1,15}(\.\d{0,2})?|\.\d{1,2})$/.test(value);
    },'Please enter valid input.');

    $(document).ready(function() {
      $('#loan_installment_start_month').on('change', function() {
        var startMonth = $(this).val();
        var tenure = $('#tenure').val();

        var startMonthParts = startMonth.split('/');
        if (startMonthParts.length === 2) {
          var startMonthNum = parseInt(startMonthParts[0]) - 1;
          var startYear = parseInt(startMonthParts[1]);

          var startDate = new Date(startYear, startMonthNum, 1);
          if (!isNaN(startDate) && tenure) {
            startDate.setMonth(startDate.getMonth() + parseInt(tenure));
            var endMonth = ("0" + (startDate.getMonth())).slice(-2) + '/' + startDate.getFullYear();
            $('#loan_installment_end_month').val(endMonth);
          }
        }
      });
    });


    $(document).on('turbo:load', function() {
      $(".filter-btn").click(function(){
        console.log('on click')
        $(".organizer-loan").toggleClass("order-enable");
      });
    })
  }
}
