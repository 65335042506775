import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee-separation"
export default class extends Controller {
  connect() {
    console.log('testing')
    $('.EmployeeSeparationFrom').validate({
      rules: {
        'employee_separation[title]': {
          required: true,
          minlength: 3,
          maxlength: 50
        },
        'meeting[start_time]': {
          required: true
        },
        'meeting[end_time]': {
          required: true
        }
      },
      messages: {
        'employee_separation[title]': {
          required: 'Please enter title.',
        },
        'meeting[start_time]': {
          required: 'Please select start time.'
        },
        'meeting[end_time]': {
          required: 'Please select end time.'
        }
      }
    });

    $('.edit_employee').validate({
      rules: {
        'employee[end_date]': {
          required: true,
        }
      }
    });

    $("#end_date_time").datepicker({
      format: 'dd-mm-yyyy',
      startDate: new Date(),
      autoclose: true,
    });

    const checkbox = $('#employee_separation_notice_period');
    checkbox.on('click', function() {
      $(".notice_period_button").trigger("click");
    });
    console.log('Testing')
    if (checkbox.is(':checked')) {
      checkbox.prop('disabled', true);
    } else {
      checkbox.prop('disabled', false);
    }
  }
}
