import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="post"
export default class extends Controller {
  connect() {
    $('#new_post').validate({
      rules: {
        "post[title]": {
          required: true,
          minlength: 3,
          maxlength: 100
        }
      },
      messages: {
        "post[title]": {
          required: 'This is a required field.',
          minlength: 'Minimum 3 characters required',
          maxlength: 'Maximum 100 characters are allowed'
        }
      }
    });

    $('#guest_sign_up').validate({
      rules: {
        "first_name": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 25
        },
        "last_name": {
          required: true,
          nameregex: true,
          minlength: 3,
          maxlength: 25
        },
        "email": {
          required: true,
          emailregex: true
        },
        "password": {
          required: true,
          passwordregex: true,
          minlength: 8,
          maxlength: 15
        }
      },
      messages: {
        "first_name": {
          required: 'First name is required',
          nameregex: 'First name contains alphabets only',
          minlength: 'First name must be 2-25 characters long',
          maxlength: 'First name must be 2-25 characters long'
        },
        "last_name": {
          required: 'Last name is required',
          nameregex: 'Last name contains alphabets only',
          minlength: 'Last name must be 3-25 characters long',
          maxlength: 'Last name must be 3-25 characters long'
        },
        "email": {
          required: 'Email is required',
          emailregex: 'Please enter a valid email address'
        },
        "password": {
          required: 'Password is required',
          minlength: 'Password must be 8-15 characters long',
          maxlength: 'Password must be 8-15 characters long'
        }
      }
    });

    $.validator.addMethod('emailregex', function(value, element){
      return this.optional(element) || /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value);
    });
    $.validator.addMethod('passwordregex', function(value, element){
      return this.optional(element) || /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).+$/.test(value);
    },'Password must contains one uppercase, lowercase, number and special character');

    $('#wrapper').addClass('toggled')
    $(".show-nested-comments").on("click", function(event) {
      event.preventDefault();

      const commentId = $(this).data("commentId");
      const nestedComments = $("#nested-comments-" + commentId);

      if (nestedComments.is(":hidden")) {
        nestedComments.show();
        $(this).text("Hide nested comments");
      } else {
        nestedComments.hide();
        $(this).text("Show nested comments");
      }
    });
  }

  showNestedComments() {
    // console.log('Hi')
  }
}
