import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reimbursment-documents"
export default class extends Controller {
  connect() {
    $('#reimbursment_documents').find(".nested-fields").each(function(index, element) {
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
          if($(input).attr('class').includes('document-name')){
            $(input).rules( "add", {
              required: true,
              minlength: 2,
              maxlength: 50,
              nameregex: true,
              messages: {
                required: "Please enter document name",
                minlength: 'Please enter atleast 2 characters',
                maxlength: 'Please do not enter more than 50 characters',
                nameregex: 'only alphabets are allowed'
              }
            });
          }
        }
      })
    })
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z. ]+$/.test(value);
    });
  }
}
