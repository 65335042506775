import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="job-opening"
export default class extends Controller {
  connect() {
    $(".filter-btn").click(function(){
      $(".job-opening-report").toggleClass("order-enable");
      $(".hiring_lead-report").toggleClass("order-enable");
      $(".job-opening").toggleClass("order-enable");
    });
    customSelect2('#job_department', true, 'Please Select department', true);
    customSelect2('#job_employment_type', true, 'Please Select employment', true);
    customSelect2('#job_status', true, 'Please Select status', true);
    customSelect2('#job_opening_id', true, 'Please Select', true);
    customSelect2('#employee_id', true, 'Please Select', true);

    $('.new_job_opening').validate({
      rules: {
        'job_opening[job_title]':{
          required:true,
          minlength: 3,
          maxlength: 50,
        },
        'job_opening[employee_id]':{
          required:true,
        },
        'job_opening[employment_type]':{
          required:true,
        },
        'job_opening[minimum_experience]':{
          required:true,
          min: 0,
          max: 100,
          step: 0.01,
        },
        'job_opening[description]':{
          required:true,
          minlength: 10,
        },
        'job_opening[department]': {
          required:true,
        },

      },
      messages: {
        'job_opening[job_title]':{
          required: 'Please enter a job title',
          minlength: 'Enter minimum 3 and maximum 50 character',
          maxlength: 'Enter minimum 3 and maximum 50 character',
        },
        'job_opening[employee_id]':{
          required: 'Please select a hiring head',
        },
        'job_opening[employment_type]':{
          required:'Please select employment type',
        },
        'job_opening[minimum_experience]':{
          required:'Please enter minimum experience required',
          min: 'Experience can not be negative',
          max: 'Only 3 digits are allowed before decimal.',
          step: 'Upto 2 decimal values are allowed', 
        },
        'job_opening[description]':{
          required:'Please enter job description',
          minlength: 'Enter minimum 10 characters',
        },
        'job_opening[department]': {
          required: 'Please select a department',
        },
      },
      errorElement: "div"
    });

    $('.edit_job_opening').validate({
      rules: {
        'job_opening[job_title]':{
          required:true,
          minlength: 3,
          maxlength: 50,
        },
        'job_opening[hiring_head]':{
          required:true,
        },
        'job_opening[employment_type]':{
          required:true,
        },
        'job_opening[minimum_experience]':{
          required:true,
          min: 0,
          max: 100,
          step: 0.01,
        },
        'job_opening[description]':{
          required:true,
          minlength: 10,
        },
      },
      messages: {
        'job_opening[job_title]':{
          required: 'Please enter a job title.',
          minlength: 'Enter minimum 3 and maximum 50 character.',
          maxlength: 'Enter minimum 3 and maximum 50 character.',
        },
        'job_opening[hiring_head]':{
          required: 'Please select a hiring head.',
        },
        'job_opening[employment_type]':{
          required:'Please select employment type.',
        },
        'job_opening[minimum_experience]':{
          required:'Please enter minimum experience required.',
          min: 'Experience can not be negative.',
          max: 'Only 3 digits are allowed before decimal.',
          step: 'Upto 2 decimal values are allowed.',
        },
        'job_opening[description]':{
          required:'Please enter job description.',
          minlength: 'Enter minimum 10 characters.',
        },
      },
      errorElement: "div"
    })

    document.addEventListener('turbo:load', () => {
      this.initializeDatatable();
    });
    document.addEventListener('refreshHiringDataTable', (event) => {
      this.refreshHiringDataTable(event.detail);
    });

    $('#job_department, #job_employment_type, #job_status').change(function() {
      var job_department = $('#job_department').val();
      var job_employment_type = $('#job_employment_type').val();
      var job_status = $('#job_status').val();
      $.ajax({
        url: $('#job-opening-report-datatable').data('source'),
        method: 'GET',
        data: { job_department: job_department,
                job_employment_type: job_employment_type,
                job_status: job_status },
        success: function(response) {
        }
      });
    });

    $('#job_opening_id, #employee_id').change(function() {
      var job_opening_id = $('#job_opening_id').val();
      var employee_id = $('#employee_id').val();
      console.log(' in lead js')
      $.ajax({
        url: $('#hiring_head-report-datatable').data('source'),
        method: 'GET',
        data: { job_opening_id: job_opening_id,
                employee_id: employee_id },
        success: function(response) {
        }
      });
    });


  }

  initializeDatatable() {
    let table_id = $('.table-id-element').attr('id')
    if (!$.fn.DataTable.isDataTable('#'+ table_id)) {
      console.log('checking the load')
      $('#'+ table_id).dataTable({
        "orderCellsTop": true,
        "processing": true,
        "pagingType": "simple_numbers",
        "oLanguage": {
          "sLengthMenu": "Show Entries _MENU_",
          "sProcessing": "<div class='ld_ind_wrp'></div>",
          "sSearch": "",
          "oPaginate": {
            "sNext": '<i class="bi bi-chevron-right"></i>',
            "sPrevious": '<i class="bi bi-chevron-left"></i>'
          },
        },
        "dom": "<'row custom_data_filter_col'<'col-sm-12 custom_datatable'f>>" +
          "<'row'<'col-sm-12 table-responsive'tr>>" +
        "<'row mgltm0'<'col-sm-5 show-entry'li><'col-sm-7'p>>"+ "<'row'<'col-sm-12'B>>" ,
          'buttons': [
            {
              extend: 'csv',
              text: 'Export CSV',
            },
            {
              extend: 'excel',
              text: 'Export Excel',
            }
          ],
      });
    }
  }

  refreshHiringDataTable(detail) {
    console.log('in refresh')
    let table_id = detail.table_id
    if ($.fn.DataTable.isDataTable('#' + table_id)) {
      $('#' + table_id).DataTable().destroy();
    }
    this.initializeDatatable();
  }
}
