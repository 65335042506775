import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="template-category"
export default class extends Controller {
  connect() {

    $(".filter-btn").click(function(){
      $(".organizer-template-category").toggleClass("order-enable");
    });

    validatorBasic();
    $('input').on('keydown', function() {
      $('.errors').text('')
    });
    $('.templateCategory').validate({
      rules: {
        "template_category[name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 50,
        }
      },
      messages: {
      "template_category[name]": {
        required: 'Please enter name.',
        nameregex: 'Please enter alphabates only',
        minlength: 'Must be 2-50 characters long',
        maxlength: 'Must be 2-50 characters long',
      }
    }});

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    },'Contains alphabets only');
  }
}
