import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="holi-day-calender"
export default class extends Controller {
  connect() {
    $(document).on("click", "#filter-button", function () {
      $('#filter-section').toggleClass('d-none')
    });

    $(".filter-btn").click(function(){
      $(".organizer-holiday").toggleClass("order-enable");
      $(".employee-holiday").toggleClass("order-enable");
    });

    validatorBasic();
    $('.EventsFrom').validate({
      rules: {
        "holi_day_calender[event]": {
          required: true,
          minlength: 3,
          maxlength: 50,
          nameregex: true,
        },
        "holi_day_calender[date]": {
          required: true
        },
        "holi_day_calender[event_image]": {
          file_format: true
        },
        "holi_day_calender[event_content]": {
          minlength: 10
        }
      },
      messages: {
        "holi_day_calender[event]": {
          required: 'This is a required field.',
          minlength: 'Must be 3-50 characters long',
          maxlength: 'Must be 3-50 characters long',
          nameregex: 'Contains alphabets only'
        },
        "holi_day_calender[event_content]": {
          minlength: 'Please enter atleast 10 character'
        }
      }});
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    },'Contains alphabets only');

    var year = (new Date).getFullYear();
    $('#holi_day_calender_date').datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      startDate: new Date(year, 0, 1),
      endDate: new Date(year, 11, 31),
    })

    $("#holi_day_from_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#holi_day_to_date').datepicker('setStartDate', null);
        } else if (selected.date != undefined) {
          var minDate = new Date(selected.date.valueOf());
          $('#holi_day_to_date').datepicker('setStartDate', minDate);
        }
      })

    $("#holi_day_to_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#holi_day_from_date').datepicker('setEndDate', null);        
        } else if (selected.date != undefined) {
          var maxDate = new Date(selected.date.valueOf());
          $('#holi_day_from_date').datepicker('setEndDate', maxDate);
        }
      });

    var initialFromDate = $('#holi_day_from_date').datepicker('getDate');
    if (initialFromDate != null){
      $('#holi_day_to_date').datepicker('setStartDate', initialFromDate);
    }

    var initialToDate = $('#holi_day_to_date').datepicker('getDate');
    if (initialToDate != null){
      $('#holi_day_from_date').datepicker('setEndDate', initialToDate);
    }


    $.validator.addMethod("file_format", function(value, element) {
      return this.optional(element) || /\.(jpe?g|png|webp)$/i.test(value);
    }, "Please select a PNG/JPEG/JPG/Webp file.");

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl, {"html": true})
    })
  }
}
