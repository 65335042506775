import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="daily-status"
export default class extends Controller {
  connect() {
    $("#daily_status_form").validate({
      rules: {
        "daily_status[notes]": {
          required: true,
          minlength: 10
        },
        "daily_status[date]": {
          required: true
        },
      },
      messages: {
        "daily_status[notes]": {
          required: "Please enter title",
          minlength: 'Please enter atleast 10 character.'
        },
        "daily_status[date]": {
          required: "Please select date"
        },
      }
    });

    $('#task-form').find(".nested-fields").each(function(index, element) {
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'number') {
          if($(input).attr('class').includes('eta')){
            $(input).rules( "add", {
              required: true,
              min: 0.01,
              max: 24,
              messages: {
                required: "Please enter ETA",
                min: "Value must be greater than zero.",
                max: 'Only 2 digits are allowed before decimal',
                step: 'Only accept 2 digits after decimal.'
              }
            });
          };
        };
      });
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'number') {
          if($(input).attr('class').includes('actual-time')){
            $(input).rules( "add", {
              required: true,
              min: 0.0,
              max: 24,
              messages: {
                required: "Please enter actual time",
                min: "Value must be greater than zero.",
                max: 'Only 2 digits are allowed before decimal',
                step: 'Only accept 2 digits after decimal.'
              }
            });
          };
        };
      });
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'number') {
          if($(input).attr('class').includes('complete')){
            $(input).rules( "add", {
              min: 0,
              max: 100,
              number: true,
              messages: {
                required: "Please enter complete %.",
                min: "Please enter % beyond 1",
                max: '% completion can not exceed beyond 100.',
                step: 'Only accept 2 digits after decimal.',
                number: 'Accept only number'
              }
            });
          };
        };
      });
      $(element).find('input').each(function(i, input) {
        if($(input).attr('class').includes('description')){
          $(input).rules( "add", {
            required: true,
            minlength: 10,
            messages: {
              required: "Please enter description.",
              minlength: 'Please enter atleast 10 characters.'
            }
          });
        };
      });
    });

    $(document).on('change', '.task_option_list', function() {
      var selectedValue = $(this).val();
      var trixContentEditable = $(this).closest('.nested-fields').find('.trix-content')[0];
      var etaField = $(this).closest('.nested-fields').find('.eta') 
      $.ajax({
        url: '/employee/tasks/'+ selectedValue +'/task_details',
        method: 'GET',
        success: function(response) {
          trixContentEditable.editor.loadHTML(response.details);
          var parsedTime = parseFloat(response.time);
          if (!isNaN(parsedTime)) {
            etaField.val(parsedTime);
          }
        }
      });
    });

    $('#daily_status_date').datepicker({
        format: 'dd/mm/yyyy',
        endDate: '+0d',
        startDate: '-33d',
        autoclose: true
    })

    if($('.work_logs_forms').length == 0){
      $('.add_fields').click();
    }
    setTimeout(function() {loadSelect2WorkLog()}, 100)
    $('.daily-status-new-section a.add_fields').click(function() {
      setTimeout(function() {loadSelect2WorkLog()}, 100)
    })

    customSelect2('#daily_status_task_ids', true);
  }
}

window.loadSelect2WorkLog = function() {
  $('.daily-status-new-section select').each(function(index, item) {
    if($(item).data('selecttwo') == true){
      console.log('Clicked on Add button loop')
      $(item).select2()
    }
  })
}