import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="card-logs"
export default class extends Controller {
  connect() {
    console.log('starting of datatable')
    $(".filter-btn").click(function(){
      console.log('on click')
      $(".card_log").toggleClass("order-enable");
    });
    $(".filter-btn").click(function(){
      console.log('on click')
      $(".employee-card-log").toggleClass("order-enable");
    });
    console.log('in cardlog ')
    customSelect2('#card_log_employee_id', true, 'All', true);
    $("#from_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      endDate: '+0d',
    }).on('changeDate input', function (selected) {
      if (!$(this).val()) {
        $('#to_date').datepicker('setStartDate', null);
      } else if (selected.date != undefined) {
        var minDate = new Date(selected.date.valueOf());
        $('#to_date').datepicker('setStartDate', minDate);
      }
      })

    $("#to_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      endDate: '+0d',
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('#from_date').datepicker('setEndDate', new Date());
        } else if (selected.date != undefined) {
          var maxDate = new Date(selected.date.valueOf());
          $('#from_date').datepicker('setEndDate', maxDate);
        }
      });

    var initialFromDate = $('#from_date').datepicker('getDate');
    if (initialFromDate != null){
      $('#to_date').datepicker('setStartDate', initialFromDate);
    }

    var initialToDate = $('#to_date').datepicker('getDate');
    if (initialToDate != null){
      $('#from_date').datepicker('setEndDate', initialToDate);
    }
    $(".sidesubmenu").on({
      mouseenter: function () {
      debugger
        $(this).addClass("opne_menu");
      },
      mouseleave: function () {
        $(this).removeClass("opne_menu");
      },
    });
    // $(document).on('turbo:load', function() {
    //   $("#filter-btn").click(function(){
    //     $(".card_log").toggleClass("order-enable");
    //   });
    //   if (!$.fn.DataTable.isDataTable('#organizers-card_logs-datatable')) {
    //     $('#organizers-card_logs-datatable').dataTable({
    //       "oLanguage": {
    //         "sLengthMenu": "Show Entries _MENU_",
    //         "sProcessing": "<div class='ld_ind_wrp'></div>",
    //         "sSearch": "",
    //         "oPaginate": {
    //           "sNext": '<i class="bi bi-chevron-right"></i>',
    //           "sPrevious": '<i class="bi bi-chevron-left"></i>'
    //         },
    //       },
    //       "orderCellsTop": true,
    //       "processing": true,
    //       "serverSide": true,
    //       "bStateSave" : false,
    //       "fnInitComplete": function(oSettings, json) {
    //           var cols = oSettings.aoPreSearchCols;
    //           console.log(cols)
    //           for (var i = 0; i < cols.length; i++) {
    //             var value = cols[i].sSearch;
    //             console.log("value", value)
    //             if (value.length > 0 && value != "null") {
    //               $(".card_log").addClass("order-enable");
    //               selection = $('.filters input.default_input, .filters select.select2-multiple')
    //               data_type = selection[i].type
    //               if (data_type == "text") {
    //                 console.log(data_type)
    //                 selection[i].value = value;
    //               } else {
    //                 record_id = selection[i].id
    //                 console.log(record_id)
    //                 $("#"+record_id).val(value.split("|"))
    //                 $("#"+record_id).parent().find(".select2-search__field").val(value.split("|"))
    //               }
    //             }
    //           }
    //           return $('.filters input, .filters select', this).on('keyup change clear', (function(_this) {
    //             return function(e) {
    //               var th;
    //               var search_value;
    //               th = $(e.target).closest("th");
    //               var value = $(e.target).val();
    //               if (typeof value == "string") {
    //                 search_value = value
    //               } else {
    //                 if (value == null) {
    //                   search_value = ""
    //                 } else {
    //                   search_value = value.join("|")
    //                 }
    //               }
    //               console.log(search_value)
    //               return _this.api().column(th.index()).search(search_value).draw();
    //             };
    //           })(this));
    //         },
    //       "ajax": {
    //         "url": $('#organizers-card_logs-datatable').data('source'),
    //        "dataSrc": function ( json ) {
    //           return json.data;
    //         },
    //         data: function (d) {
    //           return $.extend({}, d, {
    //             status: $('#status-filter').val(),
    //           });
    //         },
    //         error: function (xhr, error, thrown) {
    //           sessionExpiredErrorMessage(thrown)
    //         },
    //       },
    //       "pagingType": "simple_numbers",
    //       "columns": [
    //         {"data": "punch_time"},
    //         {"data": "employee_id"},
    //         {"data": "first_card_logs"},
    //         {"data": "last_card_logs"},
    //         {"data": "work_time_text"},
    //         {"data": "break_time_text"},
    //         {"data": "employee_punch_status"},
    //         {"data": "action"},
    //       ],
    //       "order": [[ 0, "desc" ]],
    //       "dom": "<'row custom_data_filter_col'<'col-sm-12 custom_datatable'f>>" +
    //         "<'row'<'col-sm-12 table-responsive'tr>>" +
    //       "<'row mgltm0'<'col-sm-5 show-entry'li><'col-sm-7'p>>"+ "<'row'<'col-sm-12'B>>" ,
    //       'buttons': [
    //           {
    //               extend: 'csv',
    //               text: 'Export CSV',
    //               exportOptions: {
    //                   columns: ':not(:eq(7))' // Exclude column 1 from CSV export
    //               }
    //           },
    //           {
    //               extend: 'excel',
    //               text: 'Export Excel',
    //               exportOptions: {
    //                   columns: ':not(:eq(7))' // Exclude column 1 from Excel export
    //               }
    //           }
    //       ],
    //       "columnDefs": [
    //         { orderable: false, targets: -1, className: "select-checkbox"}
    //       ]
    //     });
    //   } else {
    //     console.log('DataTable already initialized')
    //   }
      
    //   $('#datatable-custom-search').keyup(function() {
    //     $('.dataTables_wrapper .dataTables_filter input[type="search"]').val($(this).val());
    //     $('.dataTables_wrapper .dataTables_filter input[type="search"]').keyup()
    //   })
    //   $('.exel_report_btn').click(function() {
    //       $('.buttons-excel').click();
    //   });
    //   $('.csv_report_btn').click(function() {
    //       $('.buttons-csv').click();
    //   });
    //   $('.copy_report_btn').click(function() {
    //       $('.buttons-copy').click();
    //   });
    //   $('.pdf_report_btn').click(function() {
    //       $('.buttons-pdf').click();
    //   });
    //   customSelect2('.card_log-table #late_status', true, 'Please Select', true);

    //   $('#card-log-date').datepicker({
    //     format: 'yyyy-mm-dd',
    //     autoclose: true,
    //   });

    //   $('#csv_import_csv_file').change(function() {
    //     var filename = $(this).val().split('\\').pop(); // Get the filename without the full path
    //     $('#order-file-name').text(filename); // Update the content of the small tag with the filename
    //   });

    //   $('.csv_import_csv_file_browse').click(function() {
    //     $('#csv_import_csv_file').click();
    //   });
    // });
  }
}
